import React from 'react';

const importAll = (r) => {
    return r.keys().map(r);
};

const images = importAll(require.context('./gallery', false, /\.(png|jpe?g|svg)$/));

const Gallery = () => {
    const galleryStyle = {
        padding: '30px',
        textAlign: 'center',
    };

    const imgStyle = {
        width: '300px',         // Fixed width
        height: '200px',        // Fixed height
        borderRadius: '15px',
        margin: '15px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        objectFit: 'cover',     // Ensures the image covers the assigned width and height
        opacity: 0,             // Initially hidden
        transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out', // Smooth transition for opacity and transform
    };

    const imgContainerStyle = {
        position: 'relative',    // Position relative for absolute positioning of img
        overflow: 'hidden',      // Hide overflow to ensure hidden images don’t display
        width: '300px',          // Match the image width
        height: '200px',         // Match the image height
    };

  

    const galleryContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    };

    return (
        <section id="gallery" style={galleryStyle}>
            <h2>Special Moments</h2>
            <div style={galleryContainerStyle}>
                {images.map((image, index) => (
                    <div 
                        key={index} 
                        style={imgContainerStyle} 
                        onMouseOver={(e) => {
                            const img = e.currentTarget.querySelector('img');
                            img.style.opacity = '1';
                            img.style.transform = 'scale(1.05)';
                        }}
                        onMouseOut={(e) => {
                            const img = e.currentTarget.querySelector('img');
                            img.style.opacity = '0';
                            img.style.transform = 'scale(1)';
                        }}
                    >
                        <img
                            src={image}
                            alt={`Moment ${index + 1}`}
                            style={imgStyle}
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Gallery;
