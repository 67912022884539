import React, { useState } from 'react';

const Timeline = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const timelineStyle = {
    backgroundColor: '#fff',
    borderRadius: '15px',
    padding: '30px',
    marginTop: '80px', // To avoid the fixed navbar
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  };

  const eventStyle = {
    margin: '20px 0',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#f7e8f4',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
    transition: 'transform 0.2s ease-in-out',
  };

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const events = [
    {
      title: 'First Time Beside a Lake',
      description: 'Listening to songs with the same earphones. Those moments felt like a scene from a romantic movie.',
    },
    {
      title: 'First Time Playing Tic Tac Toe',
      description: 'Sitting in class together. Your laughter was contagious, making even the dullest subjects enjoyable.',
    },
    {
      title: 'First Time in CCD',
      description: 'Where you were looking beautiful. Your smile lit up the entire cafe, making everything feel perfect.',
    },
    {
      title: 'First Diwali',
      description: 'Watching the illumination together. The sparkle in your eyes matched the lights around us, making the night unforgettable.',
    },
    {
      title: 'First Holi',
      description: 'Where you colored me at night. Your playful spirit made the festival so vibrant and full of life.',
    },
  ];

  return (
    <section id="timeline" style={timelineStyle}>
      <h2>Our Journey</h2>
      {events.map((event, index) => (
        <div
          key={index}
          style={{
            ...eventStyle,
            transform: activeIndex === index ? 'scale(1.05)' : 'scale(1)',
            cursor: 'pointer',
          }}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <h3>{event.title}</h3>
          {activeIndex === index ? (
            <p>{event.description}</p>
          ) : (
            <p style={{ color: '#888' }}>Hover to see more...</p>
          )}
        </div>
      ))}
    </section>
  );
};

export default Timeline;
