import React, { useState, useEffect } from 'react';

const Proposal = () => {
  const [position, setPosition] = useState({ top: '50%', left: '50%' });
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility

  const getRandomPosition = () => {
    const randomX = Math.floor(Math.random() * 80);
    const randomY = Math.floor(Math.random() * 80);
    return { top: `${randomY}vh`, left: `${randomX}vw` };
  };

  const handleMouseEnter = () => {
    setPosition(getRandomPosition());
  };

  const handleYesClick = () => {
    setShowMessage(true); // Show the message when "Yes" is clicked
  };

  const proposalStyle = {
    textAlign: 'center',
    marginTop: '0', // Remove marginTop to avoid extra space
    position: 'relative',
    overflow: 'hidden', // Prevent overflow and hide scrollbar
    height: '100vh',
  };

  const noButtonStyle = {
    position: 'absolute',
    ...position,
    transform: 'translate(-50%, -50%)',
    padding: '15px 30px',
    backgroundColor: 'rgba(255, 111, 145, 0.85)',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '24px',
    transition: 'all 0.3s ease',
    zIndex: 10,
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes twinkling {
        0%, 100% { opacity: 0.7; }
        50% { opacity: 1; }
      }
      .animated-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-image: url('https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwyMDg4Mzh8MHwxfGFsbHwxfHx8fHx8fHwxNjMzMzI4NTc5&ixlib=rb-1.2.1&q=80&w=1080');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        animation: twinkling 3s infinite alternate;
      }
      .star {
        position: absolute;
        border-radius: 50%;
        background-color: white;
        opacity: 0.7;
        animation: twinkling 3s infinite alternate;
      }
    `;
    document.head.appendChild(style);

    const createStars = () => {
      for (let i = 0; i < 100; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        const size = Math.random() * 3 + 1;
        const x = Math.random() * 100;
        const y = Math.random() * 100;
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;
        star.style.left = `${x}vw`;
        star.style.top = `${y}vh`;
        star.style.animationDuration = `${Math.random() * 3 + 2}s`;
        document.body.appendChild(star);
      }
    };

    createStars();

    return () => {
      document.head.removeChild(style);
      const stars = document.querySelectorAll('.star');
      stars.forEach((star) => star.remove());
    };
  }, []);

  return (
    <div style={proposalStyle}>
      <div className="animated-background"></div>
      <h1 style={{ color: 'white' }}>Will you be my girlfriend?</h1>
      <button style={noButtonStyle} onMouseEnter={handleMouseEnter}>
        No
      </button>
      <button style={{ padding: '15px 30px', fontSize: '24px', marginLeft: '20px' }} onClick={handleYesClick}>
        Yes
      </button>
      {showMessage && (
        <h2 style={{ color: 'white', marginTop: '20px' }}>Good! Now you are my girlfriend!</h2>
      )}
    </div>
  );
};

export default Proposal;
