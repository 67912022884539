import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Timeline from './components/Timeline';
import Gallery from './components/Gallery';
import Proposal from './components/Proposal';
import StarryBackground from './components/StarryBackground'; // Import the starry background

// Update the components array to exclude the Chat component
const components = [
  { id: 'timeline', component: <Timeline /> },
  { id: 'gallery', component: <Gallery /> },
  { id: 'proposal', component: <Proposal /> },
];

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    if (currentIndex < components.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goToPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <Router>
      <div style={appStyle}>
        <StarryBackground /> {/* Add starry background to all pages */}
        <div style={arrowContainerStyle}>
          <button 
            onClick={goToPrevious} 
            style={buttonStyle} 
            disabled={currentIndex === 0}
          >
            &#10094; {/* Left arrow */}
          </button>
          <button 
            onClick={goToNext} 
            style={buttonStyle} 
            disabled={currentIndex === components.length - 1}
          >
            &#10095; {/* Right arrow */}
          </button>
        </div>
        <div style={{ textAlign: 'center', transition: 'opacity 0.5s ease' }}>
          {components[currentIndex].component}
        </div>
      </div>
    </Router>
  );
}

const appStyle = {
  position: 'relative',
  minHeight: '100vh',
  background: 'linear-gradient(135deg, #1a1a2e, #16213e)', // Dark gradient for starry night effect
  overflow: 'hidden', // Prevent scroll bars
};

const arrowContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '0',
  right: '0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1000,
  padding: '0 20px',
};

const buttonStyle = {
  backgroundColor: 'rgba(255, 111, 145, 0.85)',
  border: 'none',
  color: 'white',
  padding: '15px',
  cursor: 'pointer',
  fontSize: '24px',
  borderRadius: '5px',
  transition: 'background-color 0.3s, transform 0.3s',
  opacity: 0.8,
};

export default App;
