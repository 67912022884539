import React from 'react';

const StarryBackground = () => {
  const starStyle = {
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: 'white',
    animation: 'twinkle 1s infinite alternate',
  };

  return (
    <div style={starContainerStyle}>
      {Array.from({ length: 100 }).map((_, index) => (
        <div
          key={index}
          style={{
            ...starStyle,
            width: `${Math.random() * 3 + 1}px`, // Minimum size for better visibility
            height: `${Math.random() * 3 + 1}px`,
            top: `${Math.random() * 100}vh`,
            left: `${Math.random() * 100}vw`,
            animationDelay: `${Math.random() * 2}s`,
          }}
        />
      ))}
      <style>{`
        @keyframes twinkle {
          0%, 100% {
            opacity: 0.5;
          }
          50% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

const starContainerStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 0, // Background stars should be behind everything else
  pointerEvents: 'none', // Ensure users can't interact with stars
};

export default StarryBackground;
